import {useLocation} from '@remix-run/react';

export const useCurrentUrl = () => {
  const location = useLocation();

  let currentUrl = location.pathname;
  if (location.search) {
    currentUrl += `${location.search}`;
  }
  if (location.hash) {
    currentUrl += `${location.hash}`;
  }

  return currentUrl;
};
