import {useDisclosure} from '@mantine/hooks';
import {Link, matchPath, useLocation} from '@remix-run/react';
import {type FunctionComponent, useMemo} from 'react';
import {type PrefixIcon, Icon} from '#/app/components/admin/Icon.js';

const isSelected = (to: string, pathname: string, selected?: boolean, exactMatch: boolean = false) => {
  to = to.split('?')[0];
  pathname = pathname.split('?')[0];

  if (!to) {
    return false;
  }

  if (typeof selected !== 'undefined') {
    return selected;
  }

  const match = matchPath({end: exactMatch, path: to}, pathname);

  return Boolean(match);
};

type NavigationSubItemProps = {
  exactMatch?: boolean;
  label: string;
  onClick?: any;
  selected?: boolean;
  to?: string;
};

const NavigationSubItem: FunctionComponent<NavigationSubItemProps> = (props) => {
  const {exactMatch = false, label, onClick, selected: rawSelected, to = ''} = props;

  const {pathname} = useLocation();
  const selected = isSelected(to, pathname, rawSelected, exactMatch);

  const cls = useMemo(() => {
    const cls = {
      container:
        'group flex font-medium items-center pl-11 pr-3 py-0.5 leading-relaxed transition duration-150 ease-in-out rounded-md group hover:bg-slate-100 focus:outline-hidden hover:no-underline!',
      label: '',
    };
    if (selected) {
      cls.container += ' bg-slate-100 text-blue-700';
    } else {
      cls.container += ' text-slate-600 hover:text-slate-700 ';
    }

    return cls;
  }, [selected]);

  return (
    <Link
      className={cls.container}
      onClick={onClick}
      to={to}
    >
      {label && <div className={cls.label}>{label}</div>}
    </Link>
  );
};

type NavigationItemProps = {
  after?: any;
  before?: any;
  exactMatch?: boolean;
  icon?: PrefixIcon;
  items?: NavigationItemProps[];
  label: string;
  onClick?: any;
  selected?: boolean;
  to?: string;
};

export const NavigationItem: FunctionComponent<NavigationItemProps> = (props) => {
  const {
    after,
    exactMatch = false,
    icon,
    items = [],
    label,
    onClick,
    selected: rawSelected,
    to = '',
  } = props;

  const {pathname} = useLocation();
  const selected =
  (to && isSelected(to, pathname, rawSelected, exactMatch)) ||
  items.findIndex((item) => item.to && isSelected(item.to, pathname, item.selected, item.exactMatch)) > -1;
  const [opened, {toggle}] = useDisclosure(selected);

  const cls = useMemo(() => {
    const cls = {
      after: '',
      container:
        'cursor-pointer group flex items-center px-3 py-1.5 font-medium leading-relaxed text-slate-800 transition duration-150 ease-in-out rounded-md text-lg group hover:text-slate-900 hover:bg-slate-100 focus:outline-hidden hover:no-underline!',
      icon: 'flex items-center justify-center text-lg shrink-0 w-6 h-6 mr-3 -ml-1 transition duration-150 ease-in-out',
      label: 'truncate',
      wrapper: 'px-4 w-full block',
    };

    if (selected) {
      cls.icon += ' text-blue-700';
    } else {
      cls.icon += ' text-slate-400 group-hover:text-blue-700';
    }

    return cls;
  }, [selected]);

  const content = (
    <>
      {icon && <div className={cls.icon}>{typeof icon === 'string' ? <Icon icon={icon} label={label} /> : icon}</div>}
      {label && <div className={cls.label}>{label}</div>}
      {after && <div className={cls.after} />}
    </>
  );

  let linkComponent: React.ReactNode = undefined;
  if (onClick) {
    linkComponent = (
      <div
        className={cls.container}
        onClick={() => {
          toggle();
          onClick();
        }}
      >
        {content}
      </div>
    );
  } else if (to) {
    linkComponent = (
      <Link
        className={cls.container}
        onClick={() => {
          toggle();
        }}
        to={to}
      >
        {content}
      </Link>
    );
  } else {
    linkComponent = (
      <div className={cls.container} onClick={toggle}>
        {content}
      </div>
    );
  }

  return (
    <>
      {linkComponent}
      {opened && items.map((item) => <NavigationSubItem key={item.label} {...item} />)}
    </>
  );
};
