import {ScrollArea, Combobox, useCombobox} from '@mantine/core';
import {useState} from 'react';
import {useCurrentUrl} from '#/app/hooks/useCurrentUrl.js';
import {useSubmitAdvanced} from '#/app/hooks/useSubmitAdvanced.js';

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function normalizeString(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function match(_value: string, itemValue: string) {
  const value = normalizeString(_value);
  const regex = new RegExp(escapeRegExp(value), 'i');
  return regex.test(normalizeString(itemValue));
}

//
type Item = {id: number; name: string};
type CoopChooserProps = {
  defaultValue: Item;
  items: Item[];
};

export function CoopChooser(props: CoopChooserProps) {
  const {defaultValue, items = []} = props;
  const {submit} = useSubmitAdvanced();
  const currentUrl = useCurrentUrl();

  const [search, setSearch] = useState('');
  const [_, setSelectedItem] = useState<string | null>(null);
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const options = items.filter((item) => match(search, item.name.toString()));

  return (
    <Combobox
      classNames={{
        empty: 'text-base! pointer-cursor! font-medium! hover:bg-slate-100!',
        option: 'text-base! pointer-cursor! font-medium! hover:bg-slate-100!',
        search: 'focus:shadow-none! focus:border-slate-300!',
      }}
      onOptionSubmit={(val) => {
        setSelectedItem(val);
        combobox.closeDropdown();
      }}
      position="bottom-start"
      store={combobox}
      width={220}
    >
      <Combobox.Target withAriaAttributes={false}>
        <div
          className="rounded px-3 py-1.5 font-medium hover:bg-slate-100 cursor-pointer flex-row items-center space-x-2"
          onClick={() => combobox.toggleDropdown()}
        >
          {defaultValue.name}
        </div>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search by Coop name"
          value={search}
        />
        <ScrollArea.Autosize mah="50vh" type="scroll">
          <Combobox.Options>
            {options.length > 0
              ? options.map((item) => (
                <Combobox.Option
                  key={item.id}
                  onClick={() => submit('setSelectedCoopId', {__redirect: currentUrl, coopId: item.id})}
                  value={item.id.toString()}
                >
                  {item.name}
                </Combobox.Option>
              ))
              : <Combobox.Empty>Nothing found</Combobox.Empty>}
          </Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>

  );
}
