import {type FunctionComponent, type PropsWithChildren, type ReactNode} from 'react';
import {NavigationItem} from '#/app/components/admin/NavigationItem.js';
import {NavigationSection} from '#/app/components/admin/NavigationSection.js';

type NavigationProps = {children?: ReactNode;};

interface INavigation extends FunctionComponent<PropsWithChildren<NavigationProps>> {
  Item: any;
  Section: any;
}

export const Navigation: INavigation = (props: NavigationProps) => {
  const {children} = props;

  // TODO: What is this functionality about, mobile menu?
  // const [isOpen, setIsOpen] = useState(false);

  // const handleKeyUp = useCallback((e: any) => {
  //   if (e.keyCode === 27) {
  //     //esc
  //     setIsOpen(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('keyup', handleKeyUp);
  //   return () => window.removeEventListener('keyup', handleKeyUp);
  // }, [handleKeyUp]);

  // const handleOpen = useCallback((isOpen: boolean) => () => setIsOpen(isOpen), []);

  return (
    <div className="flex h-full flex-col gap-y-4">
      {children}
    </div>
  );
};

Navigation.Item = NavigationItem;
Navigation.Section = NavigationSection;

// TODO: Mobile menu
// Off-canvas menu for mobile
/*
<div className="md:hidden">
<div className="fixed inset-0 z-40 flex">
  <!--
  Off-canvas menu overlay, show/hide based on off-canvas menu state.

  Entering: "transition-opacity ease-linear duration-300"
    From: "opacity-0"
    To: "opacity-100"
  Leaving: "transition-opacity ease-linear duration-300"
    From: "opacity-100"
    To: "opacity-0"
  -->
  <div className="fixed inset-0">
    <div className="absolute inset-0 bg-slate-600 opacity-75"></div>
  </div>
  <!--
  Off-canvas menu, show/hide based on off-canvas menu state.

  Entering: "transition ease-in-out duration-300 transform"
    From: "-translate-x-full"
    To: "translate-x-0"
  Leaving: "transition ease-in-out duration-300 transform"
    From: "translate-x-0"
    To: "-translate-x-full"
  -->
  <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
    <div className="absolute top-0 right-0 p-1 -mr-14">
      <button
        className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-hidden focus:bg-slate-600"
        aria-label="Close sidebar">
        <svg className="w-6 h-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div className="flex items-center shrink-0 px-4">
      <img className="w-auto h-8" src="/img/logos/workflow-logo-on-white.svg" alt="Workflow" />
    </div>
    <div className="flex-1 h-0 mt-5 overflow-y-auto">
      <nav className="px-2">
        <a
          href="#"
          className="flex items-center px-2 py-2 text-base font-medium leading-6 text-slate-900 transition duration-150 ease-in-out bg-slate-100 rounded-md group focus:outline-hidden focus:bg-slate-200">
          <svg
            className="w-6 h-6 mr-4 text-slate-500 transition duration-150 ease-in-out group-hover:text-slate-500 group-focus:text-slate-600"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
            />
          </svg>
          Dashboard
        </a>
      </nav>
    </div>
  </div>
  <div className="shrink-0 w-14"><!--- Dummy element to force sidebar to shrink to fit close icon --></div>
</div>
</div>
*/
