import {type ReactNode, useMemo} from 'react';
import {NavigationItem} from '#/app/components/admin/NavigationItem.js';

type NavigationSectionProps = {
  children?: ReactNode;
  className?: string;
  fill?: boolean;
  items: any[];
};

export const NavigationSection = (props: NavigationSectionProps) => {
  const {children, className, fill = false, items = []} = props;

  const cls = useMemo(() => {
    const tmpCls = {container: 'flex flex-col px-3 space-y-px'};

    if (fill) {
      tmpCls.container += ' flex-1';
    } else {
      tmpCls.container += ' flex';
    }

    if (className) {
      tmpCls.container += ` ${className}`;
    }

    return tmpCls;
  }, [className, fill]);

  return (
    <div className={cls.container}>
      {children}
      {items.map((item) => (
        <NavigationItem key={item.label} {...item} />
      ))}
    </div>
  );
};
