import {Combobox, ScrollArea, useCombobox} from '@mantine/core';
import {useState} from 'react';
import {useCurrentUrl} from '#/app/hooks/useCurrentUrl.js';
import {useSubmitAdvanced} from '#/app/hooks/useSubmitAdvanced.js';

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function normalizeString(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function match(_value: string, itemValue: string) {
  const value = normalizeString(_value);
  const regex = new RegExp(escapeRegExp(value), 'i');
  return regex.test(normalizeString(itemValue));
}

const CYCLE_COLORS = {
  archived: 'text-slate-300',
  current: 'text-green-500',
  next: 'text-blue-500',
  upcoming: 'text-yellow-500',
};

//
type Item = {id: number; cycleNumber: string; deadlineAt: string; pickupAt: string; status: string};
type CycleChooserProps = {
  defaultValue: Item;
  items: Item[];
};

export function CycleChooser(props: CycleChooserProps) {
  const {defaultValue, items = []} = props;

  const {submit} = useSubmitAdvanced();
  const currentUrl = useCurrentUrl();

  const [search, setSearch] = useState('');
  const [_, setSelectedItem] = useState<string | null>(null);
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  if (!defaultValue) {
    return <div className="rounded px-3 py-1.5 font-medium text-slate-400">Select Cycle</div>;
  }

  const options = items.filter((item) => match(search, item.cycleNumber.toString()));

  return (
    <Combobox
      classNames={{
        empty: 'text-base! pointer-cursor! font-medium! hover:bg-slate-100!',
        option: 'text-base! pointer-cursor! font-medium! hover:bg-slate-100!',
        search: 'border-slate-300! focus:shadow-none!',
      }}
      onOptionSubmit={(val) => {
        setSelectedItem(val);
        combobox.closeDropdown();
      }}
      position="bottom-start"
      store={combobox}
      width={220}
    >
      <Combobox.Target withAriaAttributes={false}>
        <div
          className="flex rounded px-3 py-1.5 font-medium hover:bg-slate-100 cursor-pointer flex-row items-center space-x-2"
          onClick={() => combobox.toggleDropdown()}
        >
          <div className={`text-xs ${CYCLE_COLORS[defaultValue.status]}`}>●</div>
          <div>Cycle {defaultValue.cycleNumber} - {defaultValue.deadlineAt.substring(0, 5)}</div>
        </div>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search by Cycle number"
          value={search}
        />
        <ScrollArea.Autosize mah="50vh" type="scroll">
          <Combobox.Options>
            {options.length > 0
              ? options.map((item) => (
                <Combobox.Option
                  key={item.id}
                  onClick={() => submit('setSelectedCycleId', {__redirect: currentUrl, cycleId: item.id})}
                  value={item.cycleNumber}
                >
                  <div className="flex flex-row items-center space-x-2">
                    <div className={`text-xs ${CYCLE_COLORS[item.status]}`}>●</div>
                    <div>
                      Cycle {item.cycleNumber} - {item.deadlineAt.substring(0, 5)}
                    </div>
                  </div>
                </Combobox.Option>
              ))
              : <Combobox.Empty>Nothing found</Combobox.Empty>}
          </Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
}

