import {toNumber} from '@ivosabev/helpers/toNumber';
import {AppShell, Burger, ScrollArea} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {type ActionFunction, type LoaderFunction, redirect} from '@remix-run/node';
import {type ShouldRevalidateFunction, Form, Outlet, useLocation} from '@remix-run/react';
import {useEffect, useRef} from 'react';
import {CoopChooser} from '#/app/components/admin/CoopChooser.js';
import {CycleChooser} from '#/app/components/admin/CycleChooser.js';
import {Icon} from '#/app/components/admin/Icon.js';
import {Navigation} from '#/app/components/admin/Navigation.js';
import {HEADER_HEIGHT} from '#/app/constants.ts';
import {type Context} from '#/app/context/Context/index.js';
import {getCurrentCycle} from '#/app/context/Cycles/getCurrentCycle.js';
import {createActionHandler} from '#/app/helpers/Utils/createActionHandler.server.js';
import {createLoaderHandler} from '#/app/helpers/Utils/createLoaderHandler.server.js';
import {useLoaderDataAdvanced} from '#/app/hooks/useLoaderDataAdvanced.js';
import {useSubmitAdvanced} from '#/app/hooks/useSubmitAdvanced.js';
import {useToastAdvanced} from '#/app/hooks/useToastAdvanced.js';
import {useToastErrors} from '#/app/hooks/useToastErrors.js';
import {type AdminRootQuery} from '#/app/types/gql/graphql.js';
import {getSessionData, sessionLogin, sessionLogout} from '#/app/utils/session.server.js';

export const action: ActionFunction = (args) =>
  createActionHandler(args, {
    logout: () => sessionLogout(args.request),
    setSelectedCoopId: async (context: Context, input: Record<string, string>) => {
      const sessionData = await getSessionData(args.request);
      sessionData.coopId = toNumber(input.coopId, undefined);
      sessionData.cycleId = sessionData.coopId ? ((await getCurrentCycle(context, Number(input.coopId)))?.id as number) : undefined;
      return sessionLogin(sessionData, {redirectTo: input.__redirect});
    },
    setSelectedCycleId: async (_context: Context, input: Record<string, string>) => {
      const sessionData = await getSessionData(args.request);
      sessionData.cycleId = toNumber(input.cycleId, undefined);
      return sessionLogin(sessionData, {redirectTo: input.__redirect});
    },
  });

const query = /* GraphQL */ `
  query AdminRoot($filterBy: CycleFilterInput) {
    coops {
      edges {
        node {
          id: rowId
          name
        }
      }
    }
    cycles(filterBy: $filterBy, limit: 9999, orderBy: [["cycleNumber", "DESC"]]) {
      edges {
        node {
          cycleNumber
          deadlineAt(format: "MM/dd/yy")
          id: rowId
          pickupAt(format: "MM/dd/yy")
          status
        }
      }
    }
    systemIssues
    viewer {
      selectedCoop {
        id: rowId
        name
      }
      selectedCycle {
        cycleNumber
        deadlineAt(format: "MM/dd/yy")
        id: rowId
        status
      }
      userId: rowId
    }
  }
`;

export const loader: LoaderFunction = (args) => {
  const url = new URL(args.request.url);
  if (
    ['/admin', '/admin/', '/admin/dashboard', '/admin/dashboard/', '/admin/dashboard/reports', '/admin/dashboard/reports/'].includes(url.pathname)
  ) {
    return redirect('/admin/dashboard/reports/1');
  }

  return createLoaderHandler(args, query, async ({request}) => {
    const {coopId} = await getSessionData(request);
    return {filterBy: {coopId}};
  });
};

export const shouldRevalidate: ShouldRevalidateFunction = ({formAction, ...rest}) => {
  const isCoopIdChangeNeeded = typeof rest.nextUrl.searchParams.get('coopId') !== 'undefined';
  const isCycleIdChangeNeeded = typeof rest.nextUrl.searchParams.get('cycleId') !== 'undefined';
  const isFormAction = formAction ? formAction === '/admin' || formAction.indexOf('/admin/login') > -1 : false;
  return isCoopIdChangeNeeded || isCycleIdChangeNeeded || isFormAction;
};

function AdminRoot() {
  useToastErrors();

  const {data} = useLoaderDataAdvanced<AdminRootQuery>();
  const {coops, cycles, systemIssues, viewer} = data || {};
  const {selectedCoop, selectedCycle, userId} = viewer || {};

  const toast = useToastAdvanced();
  (systemIssues || []).forEach((description) => toast({description, duration: null, isClosable: false, status: 'error'}));

  const {submit} = useSubmitAdvanced();
  const formRef = useRef<any>(null);

  // HACK: Hack that makes Navigation links work with the old admin
  // const auth = `auth=3d22l6pb4fs9o4l96n33tb8pr1:${userId}:${selectedCoop?.id}`;

  // HACK: Hack that makes the scrollable content scroll to the top when the route changes
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollRef.current?.scrollTo({top: 0});
  }, [location.pathname]);

  const [opened, {toggle}] = useDisclosure();

  return toNumber(userId) > 0 ? (
    <AppShell
      header={{height: 50}}
      navbar={{breakpoint: 'sm', collapsed: {mobile: !opened}, width: 200}}
      padding="md"
    >
      <AppShell.Header className="shadow-xs">
        <div className="flex justify-between items-center size-full px-4">
          <div className="flex flex-row items-center gap-x-2">
            <Burger hiddenFrom="sm" onClick={toggle} opened={opened} size="sm" />
            <div className="w-48 shrink-0 items-center justify-start py-1 sm:flex hidden ml-1.5">
              <a
                className="w-4/5"
                href="https://kckoshercoop.com"
                rel="noreferrer"
                target="_blank"
              >
                <img alt="KC Kosher Coop" src="https://kckoshercoop.com/next/img/logo.png" />
              </a>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            {selectedCoop?.id !== undefined && (
              <>
                <CycleChooser defaultValue={selectedCycle} items={cycles.edges.map((v) => v.node)} />
                <div className="font-medium">/</div>
              </>
            )}
            <CoopChooser
              defaultValue={selectedCoop ?? {name: 'Non Logged In'}}
              items={coops.edges.map((v) => v.node)}
            />
          </div>
        </div>
      </AppShell.Header>
      <AppShell.Navbar>
        <AppShell.Section className="py-4" component={ScrollArea} grow>
          <Navigation>
            <Navigation.Section
              fill
              items={[
                {
                  exactMatch: true,
                  icon: 'far-grip-horizontal',
                  label: 'Dashboard',
                  to: '/admin',
                },
                {
                  icon: 'far-user',
                  items: [
                    {label: 'Customers', to: '/admin/users'},
                    {label: 'Coops', to: '/admin/coops'},
                    {label: 'Questions', to: '/admin/questions'},
                    {label: 'Comments', to: '/admin/comments'},
                  ],
                  label: 'Customers',
                },
                {
                  icon: 'far-tag',
                  items: [
                    {label: 'Products', to: '/admin/products'},
                    {label: 'Clearance', to: '/admin/clearance'},
                    {label: 'Specials', to: '/admin/specials'},
                    {label: 'Suppliers', to: '/admin/suppliers'},
                    {label: 'Categories', to: '/admin/categories'},
                  ],
                  label: 'Products',
                },
                {
                  icon: 'far-file',
                  items: [
                    {label: 'Orders', to: '/admin/orders?t=current'},
                    {label: 'Cycles', to: '/admin/cycles'},
                  ],
                  label: 'Orders',
                },
                {
                  icon: 'far-truck',
                  items: [
                    {label: 'Picklists', to: '/admin/picklists'},
                    {label: 'Inventory', to: '/admin/inventory'},
                    {label: 'Bills of Lading', to: '/admin/bols'},
                    {label: 'Carriers', to: '/admin/carriers'},
                  ],
                  label: 'Warehouse',
                },
                {
                  icon: 'far-credit-card',
                  items: [
                    {label: 'Checks', to: '/admin/checks'},
                    {label: 'Cards', to: '/admin/cards'},
                  ],
                  label: 'Payments',
                  to: '/admin/checks',
                },
                {
                  icon: 'far-exclamation-triangle',
                  items: [
                    {label: 'Products', to: '/admin/issues/products'},
                    {label: 'Payments', to: '/admin/issues/payments'},
                  ],
                  label: 'Issues',
                },
              // {
              //   icon: 'far-cog',
              //   label: 'Settings',
              //   to: '/admin/settings',
              // },
              ]}
            />
          </Navigation>
        </AppShell.Section>
        <AppShell.Section className="py-4">
          <Form method="post" ref={formRef}>
            <Navigation.Section
              items={[
                {
                  icon: <Icon flip="horizontal" icon="far-sign-out-alt" />,
                  label: 'Logout',
                  onClick: () => submit('logout'),
                },
              ]}
            />
          </Form>
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  ) : (
    <Outlet />
  );
}

export default AdminRoot;
